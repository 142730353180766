import { 
    Container, 
    Header,
    Body, 
    Form, 
    Tip, 
    Input, 
    FormInput, 
    Button, 
    Link,
    Checkbox,
    Title,
    List,
    SortTypeSelector,
    TableActionList,
    Notification
} from './Panel';

export const Panel = { 
    Container, 
    Header, 
    Body, 
    Form, 
    Tip, 
    Notification,
    Input, 
    FormInput, 
    Button, 
    Link,
    Checkbox,
    Title,
    List,
    SortTypeSelector,
    TableActionList,
};
